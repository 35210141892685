<template>
  <div>
    <div class="is-flex mb-3 bg-slate-200 p-2">
      <div
        @click.prevent="show_list_view()"
        class="px-2 py-1 rounded is-clickable"
        style="
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          background: rgb(255, 255, 255);
        "
      >
        <i class="fa-regular fa-rectangle-list"></i>
      </div>
      <div
        @click.prevent="start_create_new()"
        class="px-2 py-1 rounded is-clickable ml-auto"
        style="
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          background: rgb(255, 255, 255);
        "
      >
        <i class="fa-solid fa-plus"></i>
      </div>
    </div>
    <div class="is-flex">
      <div class="is-flex-grow-1">
        <table class="table is-fullwidth is-hoverable is-narrow">
          <thead>
            <tr>
              <th v-if="doc_type">#</th>
              <th>Emri</th>
              <th>Tipi</th>
              <th>Permbajtja</th>
              <th>Krijuar nga</th>
              <th>Krijuar me</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="document in usePrintStore().print_document_list"
              :key="document.guid"
              class="is-clickable"
            >
              <td v-if="doc_type" @click="save_select_step_print(document)">
                <i v-if="document.is_selected" class="fa-regular fa-square-check text-green-500"></i>
                <i v-else class="fa-regular fa-square text-slate-300"></i>
              </td>
              <td @click="open_print_document(document)">{{ document.name }}</td>
              <td @click="open_print_document(document)">
                {{
                  document.type == 1 ? "Fature"
                  : document.type == 2 ? "Dokument"
                  : document.type == 3 ? "Flete pune"
                  : document.type == 4 ? "Garanci"
                  : ""
                }}
              </td>
              <td @click="open_print_document(document)">{{ document_content_exerpt(document.content) }} ...</td>
              <td @click="open_print_document(document)">{{ document.created_by_name }}</td>
              <td @click="open_print_document(document)">{{ useProcessStore().date_filter(document.created_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if=" usePrintStore().print_document.guid || usePrintStore().print_document.guid == 0 " class="w-1/2 ml-3" >
        <div class="is-flex mb-2">
          <div>
            <label for="">Emri </label>
            <p v-if="is_read_only">
              {{usePrintStore().print_document.name}}
            </p>
            <input v-else
              v-model="usePrintStore().print_document.name"
              class="input is-small"
              type="text"
            />
          </div>
          <div class="is-flex  ml-3">
            <div>
              <p for="">Tipi</p>
              <p v-if="is_read_only">
                {{usePrintStore().print_document.type == 1 ? 'Fature' : 'Dokument'}}
              </p>
              <div class="select is-select is-small mr-2">
                <select v-model="usePrintStore().print_document.type">
                  <option v-if="doc_type == 1 || doc_type == null" :value="1">Fature</option>
                  <option v-if="doc_type == 2 || doc_type == null" :value="2">Dokument</option>
                  <option v-if="doc_type == 3 || doc_type == null" :value="3">Flete pune</option>
                  <option v-if="doc_type == 4 || doc_type == null" :value="4">Garanci</option>
                </select>
              </div>
            </div>
            <div v-if="usePrintStore().print_document.type == 3" class="field">
              <p>Tipi i sherbimit</p>
              <div class="select is-select is-small">
                <select v-model="usePrintStore().print_document.service_type">
                  <option :value="null" >Asnje sherbim</option>
                  <option v-for="service_type in useJobStore().service_types" :key="service_type.guid" :value="service_type.guid" >{{service_type.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="buttons has-addons is-right ml-auto is-align-items-flex-end">
            <button @click.prevent="show_html_content = true"
              :class="{'bg-green-50' : show_html_content}"
              class="button is-small ">Text</button>
            <button v-if="!is_read_only" @click.prevent="show_html_content = false; html = process(usePrintStore().print_document.content)"
            :class="{'bg-green-50' : !show_html_content}"  
               class="button is-small ">Html</button>
          </div>
        </div>
        <div v-if="show_html_content">
          <div class="codeBlock" 
            v-if="usePrintStore().print_document.content" 
            v-html="usePrintStore().print_document.content"
            spellcheck="false" >
          </div>

        </div>
        <div v-else>
          <pre class="codeBlock" 
            v-if="usePrintStore().print_document.content" 
            contenteditable 
            spellcheck="false" 
            @keyup="bind_changes"
           >{{html}}</pre>
        </div>
        <div v-if="!is_read_only" class="is-flex mt-3">
          <div class="tags  w-3/4">
            <span v-for="(key, index) in usePrintStore().print_document.keys_list" :key="index" 
              class="tag is-rounded is-light">
              @{{key}} 
              <span v-if="usePrintStore().print_variables[key]?.description" class="ml-2">
                <help-tooltip
                  :entity="key"
                  :content="usePrintStore().print_variables[key]?.description"
                  :icon_class="'text-sky-500'"
                ></help-tooltip>
              </span>
            </span>
          </div>
          <a @click.prevent="save_template()" href="" class="button bg-green-50 is-small ml-auto" >
            Ruaj dokumentin
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
    import { useProcessStore } from "@/stores/process";
    import { usePrintStore } from "@/stores/print";
    import { useJobStore } from "@/stores/job";
    import helpTooltip from '@/components/help-tooltip'
    function process(str) {
      if(str){
        return str 
      }
      else {
        return 'Print template...'
      }
    }
    function bind_changes(event) {
      usePrintStore().print_document.content = event.target.innerText
    }
</script>

<script>
import Api from "@/services/Api";
export default {
  components: {
  },
  props: ['step', 'doc_type', 'is_read_only'],
  name: "app",
  data() {
    return {
      raw_html_tag: '',
      show_html_content: true,
      html: ''
    };
  },
  async created(){
      await usePrintStore().get_print_documents(this.step, this.doc_type)

      // Get job service_types
      await useJobStore().get_job_services_types()

      // Get keys list
  },
  methods: {

    async save_template() {
      await Api(true)
        .post("/print/save/template", {
          document: usePrintStore().print_document,
          step: this.step
        })
        .then((res) => {
          // If create new
          if (usePrintStore().print_document.guid == 0) {
            usePrintStore().print_document_list.unshift(res.data[0]);
            this.$toast.success(
              (res.data[0].type == 1
                ? "Fatura "
                : res.data[0].type == 2
                ? "Dokumenti "
                : "") + "u shtua me sukses."
            );
          } else {
            // Find and update
            usePrintStore().print_document_list = [
              ...usePrintStore().print_document_list.map((d) => {
                if (d.guid == res.data[0].guid) {
                  d = { ...res.data[0] };
                }
                return d;
              }),
            ];
            this.$toast.success(
              (res.data[0].type == 1
                ? "Fatura "
                : res.data[0].type == 2
                ? "Dokumenti "
                : "") + "u ruajt me sukses."
            );
          }
          usePrintStore().print_document = {};
        });
    },
    start_create_new() {
      usePrintStore().print_document = {};
      usePrintStore().print_document.guid = 0;
      usePrintStore().show_create_new = true;
      usePrintStore().show_list = false;
      usePrintStore().print_document.content = '...'
    },
    show_list_view() {
      usePrintStore().print_document = {};
      usePrintStore().show_create_new = false;
      usePrintStore().show_list = true;
    },
    document_content_exerpt(d) {
      if (d && d.content) {
        const htmlString = d.content;
        const words = htmlString.split(" ").slice(0, 2).join(" ");
        return words;
      }
      return "";
    },
    open_print_document(d) {
      usePrintStore().print_document = { ...d };
      this.get_keys_list(usePrintStore().print_document.content)
      this.html = usePrintStore().print_document.content
    },

    async save_select_step_print(doc){
      doc.is_selected = !doc.is_selected
      await usePrintStore().save_select_step_print(doc, this.step)
      .then(res => {
        if(res.data > 0){
          this.$toast.success('Ndryshimi u ruajt me sukses')
        }
      })
    },
    get_keys_list(content){
      // Input HTML code
      const inputHTML = content;

      // Regular expression pattern to match words after "@"
      const regexPattern = /(?<=@)[\w.]+/g;

      // Extract words after "@" from input HTML
      const extractedWords = inputHTML.match(regexPattern);

      // Output the extracted words
      // console.log('list...', extractedWords);
      usePrintStore().print_document.keys_list = extractedWords
    }
  },
  computed:{
    document_type_list(){
      return []
    }
  }
};
</script>

<style>
  .my-editor {
    /* background: #2d2d2d; */
    color: #000;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
    caret-color: #000;
  }

  .prism-editor__textarea:focus {
    outline: none;
  }

#codeBlock {
  background-color: #f8f8f8;
  padding: 10px;
  font-family: monospace;
  color: #333;
  overflow: auto;
  white-space: pre;
}


</style>