<template>
    <vue-final-modal @before-open="getProperties" v-slot="{ close }" v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content" :esc-to-close="true" :click-to-close="true"
    :styles="{
        width: '100%',
        margin: '0 auto'
    }"
    :content-style="{
      width: 'auto',
      background: '#fff', 
      marginTop: '25px'
    }">
    <p class="is-flex bg-gradient-to-r from-slate-50 to-slate-300">
        <span class="subtitle m-2">{{properties.title}}</span>
        <button @click="close" class="delete ml-auto m-3"></button>
    </p>
    <div class="modal__content p-3">
        <div v-if="!properties.empty" class="has-background-white mt-1">
            <print-template :step="step" :doc_type="doc_type" :is_read_only="true"></print-template>
        </div>
    </div>
    </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import PrintTemplate from '../components/print-template.vue'
export default {
    data(){
        return {
            properties: { empty: true },
            doc_type:{ empty: null }
        }
    },
    methods: {
        getProperties(event) {
            this.step = event.ref.params.value.step
            this.doc_type = event.ref.params.value.doc_type
            this.properties = event.ref.params
        }
    },
    components: {
        VueFinalModal,
        PrintTemplate
    },
}
</script>

<style>
.modal__title {
  margin: 1.6rem;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal-content{
  width:80%!important;
}
</style>